import React from 'react'
import styles from './styles.module.scss'
import LogoWithText from '../../_atoms/LogoWithText/LogoWithText'
import HeaderLinks from "../../_molecules/HeaderLinks/HeaderLinks"
import { useGetSmsUserId } from "../../../hooks/user/useAuth"
import HeaderProfile from "../../_molecules/HeaderProfile/HeaderProfile"

const signInLinks = [
    { text: 'Каталог запчастей', href: 'https://shop.stock-pro.net/catalog', target: '_blank' },
    { text: 'О проекте', href: 'https://stock-pro.net/',  target: '_blank' },
    { text: 'Поддержка', href: 'https://partsauto.expert/faq-support',  target: '_blank' },
]


const Header = () => {
    const userId = useGetSmsUserId()

    return (<div className={styles.wrap}>
        <div className={styles.wrap__container}>
            <div className={styles.logoAndLinks}>
                <LogoWithText/>
                <HeaderLinks links={signInLinks}/>
            </div>
            {userId ? <HeaderProfile/> : null}
        </div>
    </div>)
}

export default Header
